import React,{useState,useEffect} from 'react'
import { TextField,InputAdornment } from '@mui/material'
import {Button} from '@mui/material'
import { auth } from '../../firebase.config';
import {firestore} from "../../firebase.config"
import {doc,getDocs,orderBy,setDoc,collection,query,where,deleteDoc, updateDoc,} from "firebase/firestore"
import { Link, useNavigate } from 'react-router-dom'
import { getAuth, sendEmailVerification, signInWithEmailAndPassword } from "firebase/auth";
import { MdMail, MdPassword } from 'react-icons/md'
export default function SecondLogin() {
    const [email,setEmail] =  useState()
    const [Password,setPassword] = useState()
    
    const history = useNavigate()
    const getUser = async () =>{
       
        const q = query(collection(firestore, "users"),where("email", "==", email.toString()));
        const table = []
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          table.push(doc.data())
        });
        return table[0]
    }
    const  signInWithEmail = async () => {
        
                   
        if(!email || !Password){
            alert("please complete all fields before registering")
            return
        }
        try {
          let isSuccess = true
          const userCredential = await signInWithEmailAndPassword(auth, email, Password).catch(
            (error)=>{
                alert("an error has occurred please check your internet connection, your information and try again")
                isSuccess =false    
            }
          );
          if(!isSuccess){
              return  
          }
          const user = userCredential.user;
      
          // Check if the user's email is verified
          if (!user.emailVerified) {
                                   
            await sendEmailVerification(user)
            alert("You aren't signed in with a verified email, new message has been sent to your email box. ")
            console.log('User is signed in with a verified email.');
          } else {
            alert("welcome to Table Montreal-Afrique")
            const u = await getUser()
            localStorage.setItem("USER",JSON.stringify(u))
            if(u.status === "admin"){
                history("/")
            }
            else{
                history("/")
            }
          }
        } catch (error) {
          alert("An error has occurred please check your internet connection, your information and try again")
          console.log('Error signing in:', error.message);
        }
      }
    useEffect(
        ()=>{
          if(!localStorage.getItem("USER")){
            history("/login")
            }
            else if(localStorage.getItem("USER") && JSON.parse(localStorage.getItem("USER"))["status"] === "admin"){
              history("/")     
            }
            else if(localStorage.getItem("USER") && JSON.parse(localStorage.getItem("USER"))["status"] !== "admin"){
            
             history("/") 
            }
     
        }
    ) 
    return (
    <div className='min-h-screen h-auto p-0 md:p-10 bg-gray-100 grid grid-cols-1 md:grid-cols-2'>
        <div className='p-[45px] w-full bg-white rounded-tl-lg rounded-bl-lg grid-cols-1'>
            <img className=' h-32' src ="logo.png"/>
            <h1 className='font-bold text-[20px] text-start mb-1'>LOG IN TO YOUR ACCOUNT</h1>
            <h3 className='mb-5 text-[#555]'>Welcome back, select method to login:</h3>
            <div className='flex gap-5 mb-5'>
                <div className='border-2 border-gray-300 rounded-md  py-2 px-3 flex gap-1 cursor-pointer items-center'><img src ="google.png" className='w-7'/> Google</div>
                <div className='border-2 border-gray-300 rounded-md  py-2 px-3 flex gap-1 cursor-pointer items-center'><img src ="facebook.png" className='w-7'/> Facebook</div>
                
            </div>
            <TextField  InputProps={{startAdornment: (
    <InputAdornment position="end">
      <MdMail />
    </InputAdornment>
)}} onChange={(e)=>setEmail(e.target.value)} value={email} id="email" label="Email*"  className='w-full' variant="outlined" />
            <div className='py-2'></div>
            <TextField  
            InputProps={{startAdornment: (
                <InputAdornment position="end">
                  <MdPassword />
                </InputAdornment>
            )}}
            onChange={(e)=>setPassword(e.target.value)} value={Password} id="password" label="Password*"  className='w-full' variant="outlined" />
            <div className='mt-3'></div>
            <Link to ="/forget-password"><h3 className='mb-5 text-[#555]'>Forgot password ? <span className='text-orange-500 cursor-pointer'>click here</span></h3></Link>
            <Button onClick={()=>signInWithEmail()} className='w-full' variant="contained">SIGN IN</Button>
            <h3 className='mb-10 text-[#555] mt-5'>Don't have account ?<Link to ="/register"> <span className='text-orange-500 cursor-pointer'>create an account</span></Link></h3>
            
        </div>
        <div  style={{backgroundImage:"url(https://images.pexels.com/photos/6348129/pexels-photo-6348129.jpeg?auto=compress&cs=tinysrgb&w=600)",backgroundRepeat:"no-repeat",backgroundSize:"cover",backgroundPosition:"center"}} className='hidden md:block md:grid-cols-1 h-[100%] bg-orange-50 rounded-tr-lg rounded-br-lg relative'>
            <div className='rounded-tr-lg rounded-br-lg absolute top-0 left-0 right-0 bottom-0 bg-gradient-to-r from-orange-400 opacity-10 '>

            </div>
        </div>
    </div>
  )
}
